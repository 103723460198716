.image-content-home {
  width: 4%;
  height: 70%;
  border-radius: none;
  // overflow: hidden;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // align-content: stretch;
  position: relative;
  z-index: 104;
  transition: all 0.3s ease;
  filter: invert(1) grayscale(1);
}

.image-content-home:hover {
  cursor: pointer;
  color: white;
  width: 4.5%;
}