// @import "../../../index.scss";
.sider-right {
  font-family: 'Roboto Mono Local', monospace;
  // overflow: hidden;
  // position: fixed;
  right: 40px;
  border-left: 1px solid black;

  .navTable{
    display: flex;
    flex-direction: column;
  }

  .header-right-box {
    width: 100%;
    padding-left: 35px;
    position: absolute;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    position: relative;

    .text-gray {
      margin-left: 5px;
      font-weight: 700;
    }

    .text-gray-safari {
      margin-left: 5px;
      font-weight: 500;
      color: gray;
      margin-bottom: 10px;
    }

    .text-gray-safari2 {
      margin-left: 5px;
      font-weight: 500;
      color: gray;
      margin-top: 10px;
    }

    .text-title-experiments {
      margin: 0;
      cursor: pointer;
      padding: 5px;
      font-weight: 700;

      &:hover {
        background-color: black;
        color: white;
      }
    }

    .text-title-experiments-active {
      margin: 0;
      cursor: pointer;
      padding: 5px;
      background-color: black;
      color: white;
      font-weight: 700;
    }

    .number-project {
      float: right;
    }

    .list-project-name {
      //max-height: 300px;
      overflow-y: scroll;
      .text-title-project {
        color: gray;
        margin: 0;
        cursor: pointer;
        padding: 5px;
        padding-top: 7.5px;
        padding-bottom: 7.5px;

        &:hover {
          background-color: black;
          color: white;
        }
      }

      .text-title-project-active {
        margin: 0;
        cursor: pointer;
        padding: 5px;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        background-color: black;
        color: white;
      }
    }

    .list-project-name::-webkit-scrollbar {
      display: none;
    }
  }

  .gif-right-container {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .gif-image {
      width: 80%;
      height: auto;
    }

    .gif-image-floating-man {
      width: 50%;
      height: auto;
      margin: 0 auto;
    }
  }
  .button-right {
    border: 1px solid black;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    // padding-bottom: 20px;
    // padding-top: 20px;
    border-radius: 0;
    height: 70%;
    font-family: 'Roboto Mono', monospace;
    display: inline-block;
    font-weight: 400;
    background: black;
    color: white;
    // font-size: calc(0.8vw);
    width: 75%;
    text-transform: uppercase;
    cursor: pointer;
  }

  .button-right:hover {
    color: black;
    background: none;
    border: 1px solid black;
  }

  .content-right-box {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    padding-left: 35px;

    .header-right {
      height: 50%;
      width: 100%;
    }

    .number-project {
      float: right;
    }

    .right-info-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      //top: 50%;
      font-weight: 700;
    }

    .right-info-container-safari {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      font-weight: 500;
    }

    .right-section {
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: initial;
    }

    .justify-start{
      justify-content: start;
    }

    .justify-center{
      justify-content: center;
    }

    .justify-end{
      justify-content: end;
    }
  }
}

