.layout-desktop-container {
  height: 100vh;
  width: 100vw;
  background: none;
  display: block;
  justify-content: center;
  align-items: center;

  .content {
    position: relative;
  }

  .content ::-webkit-scrollbar {
    display: none;
  }

  .header-desktop-container {
    transform: translateY(var(--offset-from-top)) translateX(var(--offset-from-left));
    background: none;
    position: fixed;
    z-index: 1; // Was 100
    padding: 0;
    top: 0;
    mix-blend-mode: difference;

    .header-info-container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 40px;

      .line-header {
        width: var(--line-width);
        border-bottom: 1px solid white;
      }
    }

    .header-name {
      display: flex;
      align-items: center;
      width: 250px;
      font-size: 16px;
      justify-content: center;
      text-align: center;
      font-family: "Altoma", monospace;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  // The styles for the main content within the website
  .content-container {
    position: relative;
    display: block;

    .title-panel {
      z-index: 2;
      position: fixed;
      mask-image: url(#grid-mask);
    }

    .scroll-trigger {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      background-color: #f5f5f5;
      align-items: center;
    }

    .content-base {
      overflow-x: hidden;



      .content-home-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 40px;

        .content-home {
          height: 100%;
        }
      }
    }
  }

  .footer-desktop-container {
    transform: translateX(var(--offset-from-left));
    // margin-bottom: var(--offset-from-bottom);
    background: none;
    position: fixed;
    z-index: 1; // Was 100
    padding: 0;
    bottom: 0;
    mix-blend-mode: difference;
    // background-size: cover;
    // background-color: transparent;
    // transition: background-color 0.2s ease;

    .footer-info-container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 40px;

      .line-footer {
        border-bottom: 1px solid white;
      }

      .gif-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        filter: invert(1);
      }

      .footer-info-copyright {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: "Roboto Mono", monospace;
        padding: 0 40px;
        text-transform: uppercase;

        p {
          font-size: 14px;
        }
      }
    }

    .footer-desktop-background {
      padding: 0 40px;
    }
  }
}

.time-loading-home {
  font-size: 18px;
  font-family: "Altoma", monospace;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  .time-loading-init {
    margin-left: 5px;
    animation: StrobeEffect 0.3s infinite;
  }
}

.title-desktop {
  top: 0;
  width: 100%;
  opacity: 100%;
  z-index: 1;
  // background-color: gray;
  // background-color: #f5f5f5;
  position: sticky;
  // transform: translateX(var(--offset-from-left));
  // transform: translate();
  pointer-events: all;
}

.title-icon {
  margin-top: 11px;
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  z-index: 1;
  justify-content: center;
  mix-blend-mode: difference;
}

.scroll-indicator {
  pointer-events: none;
  cursor: none;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  mix-blend-mode: difference;

  .scroll-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-family: "Roboto Mono", monospace;
    font-size: 14px;
    color: white;
    letter-spacing: 2px;
    // animation: blink 0.2s infinite ease-in-out;
  }

  @keyframes blink {
    0%, 10% {
      opacity: 100%;
    }

    50%{
      opacity: 0%;
    }

    100%{
      opacity: 100%;
    }
  }

  .scroll-bar {
    width: 2px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.2);
    /* Background "track" */
    position: relative;
    overflow: hidden;
    /* Ensures the scrolling line stays within bounds */

    .scroll-line {
      width: 2px;
      height: 0%;
      background-color: white;
      position: absolute;
      top: 0;
      animation: scroll-down 0.3s infinite ease-in-out;
    }

    @keyframes scroll-down {

      0%, 10%{
        height: 0%;
        opacity: 100%;
      }

      // 70% {
      //   height: 100%;
      //   opacity: 100%;
      // }

      50% {
        height: 100%;
      }

      100%{
        opacity: 0%;
      }
    }
  }

}

#app {
  position: relative;
  z-index: 10;
  height: 100dvh;
  max-height: 100dvh;
}

.container {
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;

  .wrapper {
    // transform: translateY(var(--offset-from-top));
    height: 100%;
    max-height: 100%;
    padding: 0vmax;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-container {
      overflow: scroll;
      display: inline-block;
      height: 100%;
      width: 100%;

      .title-video-player {
        object-fit: cover;
        opacity: 0;
        display: block;
      }
    }
  }
}

#webgl {
  position: fixed;
  z-index: 0;
  inset: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.3s ease;
  cursor: none;
}

.blendTest {
  width: 200px;
  height: 200px;
  // mix-blend-mode: difference;
  position: absolute;
  background-color: white;
  z-index: 100;
}