
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
  'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Altoma' 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
                  from 180deg at 50% 50%,
                  #16abff33 0deg,
                  #0885ff33 55deg,
                  #54d6ff33 120deg,
                  #0071ff33 160deg,
                  transparent 360deg
  );
  --secondary-glow: radial-gradient(
                  rgba(255, 255, 255, 1),
                  rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
                  #00000080,
                  #00000040,
                  #00000030,
                  #00000020,
                  #00000010,
                  #00000010,
                  #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
                    to bottom right,
                    rgba(1, 65, 255, 0),
                    rgba(1, 65, 255, 0),
                    rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
                    #ffffff80,
                    #ffffff40,
                    #ffffff30,
                    #ffffff20,
                    #ffffff10,
                    #ffffff10,
                    #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
  position: relative;
  background: none;
  // overflow: hidden;
  overflow-x: hidden;
}

.style-text-limit-number-line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.color-burn-bg
{
  background-color: rgb(245, 245, 245);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1000;
  opacity: 1;
}

.noise-home {
  background-image: url("assets/gif/LoopableNoise.gif");
  background-repeat: repeat;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.25; // 0.18 Original
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 105;
  pointer-events: none;
  background-size: 5%; // 5% Original
}

@font-face {
  font-family: 'Altoma';
  src: local("Altoma"), url('./assets/font/Altoma.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto Mono Local';
  src: local("Roboto Mono Local"), url('./assets/font/RobotoMono-VariableFont_wght.ttf') format('opentype');
}

// @font-face {
//   font-family: 'Dosis';
//   src: local("Dosis"), url('./assets/font/Dosis-VariableFont_wght.ttf') format('opentype');
// }

@keyframes StrobeEffect {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scrollItemCard {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 0.01;
  }
  100% {
    opacity: 0;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fadeInContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes StrobeEffect {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lottie-player-container2 {
  svg {
    border-radius: 5px;
  }
}

@keyframes fadeInContentImage {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInContentText {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media not all and (min-resolution:.001dpcm) { /* Safari 10.1+ và Firefox 48+ */
  @supports (-webkit-scrollbar: none) {
    .sider-left .content-left-box {
      font-weight: 500 !important;
    }
  }
}
