.home-intro-mobile {
  width: 100%;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
  font-size: 2.6vw;
  line-height: 3.2vw;
  font-weight: 700;
  overflow-y: scroll;

  .header-slide-container {
    position: absolute;
    bottom: calc(100vw + 15vh);
    margin-left: 5%;
    font-family: "Arial", monospace;
    animation: fadeInContent 0.5s ease-in-out;

    .text-title-mobile {
      font-weight: 700;
      font-size: 10vw;
      line-height: 48px;
      margin-bottom: 5px;
    }

    .description {
      font-weight: 700;
      font-size: 2.4vw;
      line-height: 11px;
      word-spacing: 0;
      letter-spacing: 0;
      margin-bottom: 20px;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }
}

.div-loading-container {
  width: 100%;
  height: 100%;

  .loading-first-mobile {
    padding: 0 12%;
    height: 85%;
    bottom: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .span-text-loading {
      margin-right: 40px;
    }
  }
}

.intro-container-mobile {
  text-align: center;
  height: 85%;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .description-intro-mobile {
    font-size: 2.6vw;
    line-height: 3.2vw;
  }

  .image-group-container {
    position: relative;
    width: 100%;

    .image-home-intro {
      width: 100%;
      padding: 25%;
      height: auto;
      margin-top: 15%;
      left: 0;
      top: -40%;
      position: absolute;
    }

    .text-start-intro {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    .text-end-intro {
      width: 100%;
      bottom: 0;
      text-align: center;
      position: absolute;
      margin-bottom: 5px;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    @keyframes StrobeEffect1 {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .image__circle-home-intro {
      width: 90%;
      height: auto;
      padding: 10%;
    }
  }
}

.intro-container-mobile2 {
  text-align: center;
  height: 85%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .description-intro-mobile {
    font-size: 2.6vw;
    line-height: 3.2vw;
  }

  .image-group-container {
    position: relative;
    width: 100%;

    .image-home-intro {
      width: 100%;
      padding: 25%;
      height: auto;
      margin-top: 15%;
      left: 0;
      top: -40%;
      position: absolute;
    }

    .text-start-intro {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    .text-end-intro {
      width: 100%;
      bottom: 0;
      text-align: center;
      position: absolute;
      margin-bottom: 5px;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    @keyframes StrobeEffect1 {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .image__circle-home-intro {
      width: 90%;
      height: auto;
      padding: 10%;
    }
  }
}

.intro-container {
  text-align: center;
  position: absolute;
  height: 85%;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 15%;

  .button-right {
    border: 1px solid black;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 15px;
    padding-top: 15px;
    border-radius: 0;
    height: auto;
    font-family: "Roboto Mono", monospace;
    display: inline-block;
    overflow: hidden;
    font-weight: 400;
    background: black;
    color: white;
    font-size: calc(0.8vw);
    margin-left: 40px;
    width: calc(100% - 40px);
    text-transform: uppercase;
  }

  .image-group-container {
    position: relative;
    width: 100%;

    .image-home-intro {
      width: 100%;
      padding: 25%;
      height: auto;
      margin-top: 15%;
      left: 0;
      top: -40%;
      position: absolute;
    }

    .text-start-intro {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    .text-end-intro {
      width: 100%;
      bottom: 0;
      text-align: center;
      position: absolute;
      margin-bottom: 5px;
      animation: StrobeEffect1 0.3s linear infinite;
    }

    @keyframes StrobeEffect1 {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .image__circle-home-intro {
      width: 90%;
      height: auto;
      padding: 10%;
    }
  }
}

.box-line-container {
  width: 10%;
  right: 0;
  border-left: 2px solid black;
  position: absolute;
  height: 100%;
}


.lottie-player-container {
  height: 100%;
  width: 100%;
}
