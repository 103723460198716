.footer-mobile-container {
  height: 15%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  font-weight: bold;
}

.footer-intro-mobile {
  border-top: 2px solid black;
  padding: 0 8%;
  z-index: 500;
  cursor: pointer;
  font-size: 2.6vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .text-footer-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .icon-transfer {
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
    background-color: black;
    color: white;
    padding: 18px;
    border-radius: 50%;
  }

  .text-create-mobile {
    display: flex;
    align-items: center;
  }

  .icon-warning {
    margin-right: 5px;
    font-size: 20px;
  }

  .text-intro-footer {
    width: 100%;
    text-align: center;
    margin-top: 2%;
  }
}
