.project-list-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer-intro-mobile {
  border-top: 2px solid black;
  position: absolute;
  bottom: 0;
  height: 15%;
  width: 100%;
  padding: 0 8%;

  .text-footer-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .icon-transfer {
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
    background-color: black;
    color: white;
    padding: 18px;
    border-radius: 50%;
  }

  .text-create-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    font-size: 2.6vw;
    width: 40vw;
    padding: 6px;
  }

  .icon-warning {
    margin-right: 5px;
    font-size: 20px;
  }
}

.projects-list-mobile-container {
  width: 100%;
  position: absolute;
  bottom: 15%;
}

.project-mobile-container {
  width: 100%;
  height: 100vw;
  position: relative;
  border-top: 2px solid black;
  cursor: pointer;
  font-family: "Roboto Mono", monospace;

  .button-container {
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center;

    .button-view-detail {
      font-family: "Roboto Mono", monospace;
      background-color: black;
      color: white;
      padding: 7px;
      border: none;
      font-size: 2.6vw;
      width: 40vw;
    }
  }

  .index-project {
    position: absolute;
    bottom: 15px;
    right: 10px;
    font-size: 25px;
  }

  .project-info-mobile {
    padding-left: 20px;
    position: absolute;
    opacity: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .p-title {
      font-size: 10px;
      margin-top: 20px;
    }

    .p-title2 {
      font-size: 10px;
      margin-top: 10px;
    }

    .p-title-value {
      font-size: 35px;
      line-height: 35px;
      max-width: 200px;
      min-height: 70px;
      margin-top: 10px;
    }

    .p-services {
      font-size: 10px;
      color: rgb(90, 90, 90);
    }

  }

  .project-name {
    font-size: 7vw;
    font-weight: 700;
    line-height: 47px;
    text-transform: uppercase;
  }

  .image-project-mobile {
    position: absolute;
    width: 100%;
    border: none;
    height: 100vw;
    opacity: 0.7;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

