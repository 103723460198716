// @import '../../index.scss';

.mobile-container {
  width: 100vw;
  height: 100%;
  position: fixed;
  bottom: 0;

  .header-mobile {
    background: none;
    width: 100vw;
    border-bottom: 2px solid;
    margin: 0;
    padding: 0;
  }

  .content-mobile {
    width: 100%;
    height: 90%;
    margin: 0;
    padding: 0;
    background: none;
    overflow-y: scroll;
  }

  .header-mobile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    z-index: 100;
    height: 100%;
    padding: 0 10px;

    .logo-mobile-container {
      width: 25%;
      height: 100%;
      padding: 5px;

      .logo-mobile {
        width: 100%;
        height: auto;
      }
    }

    .title-header {
      font-family: "Roboto Mono", monospace;
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      margin-left: -4%;
    }
  }
}

.mobile-container::-webkit-scrollbar {
  display: none;
}
