.list-project-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .line-home-container {
    position: absolute;
    padding: 0 40px;
    height: 100%;

    .line-home-box {
      width: 100%;
      height: 100%;

      .div-parent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .col-home-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .col-home-x {
          position: absolute;
          width: calc(100% - 20px);
          height: 100%;
        }

        .col-home-y {
          position: absolute;
          width: 100%;
          height: calc(100% - 20px);
        }
      }
    }
  }

  .header-list-project {
    width: 100%;
    font-family: "Arial", monospace;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: calc(40px + 4%);
    padding-bottom: var(--padding-vertical);
    padding-top: var(--padding-vertical);

    .title-experiments {
      font-weight: 700;
    }

    .description-experiments {
      font-weight: 700;
      padding-top: 10px;
    }
  }

  .list-project-bg {
    padding: 0 40px;

    // .col-item-project {
    // }

    .card-box-container {
      display: flex;
      flex-direction: row;
      font-family: 'Roboto Mono', monospace;
      position: relative;

      .overlay {
        position: absolute;
        padding: 9px;
        width: 100%;
        z-index: 101;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .overlay-bg, .centered-button {
          position: inherit;
          opacity: 0;
          width: calc(100% - 18px);
          height: calc(100% - 18px);
          border: none;
          transition: opacity 0.3s ease;
        }

        .overlay-bg {
          border-radius: 5px;
          background-color: lightgray; //#f5f5f5
        }

        .centered-button {
          cursor: pointer;
          // font-size: 12px;
          font-weight: 600;
          font-family: 'Roboto Mono', monospace;
          background: none;
        }
      }

      &:hover .overlay-bg {
        opacity: 0.7;
      }

      &:hover .centered-button {
        opacity: 1;
      }
      
      // Previous Implementation
      // .centered-button {
      //   top: 50%;
      //   left: 50%;
      //   min-width: 150px;
      //   transform: translate(-50%, -50%);
      //   padding: 7px 20px;
      //   position: absolute;
      //   border-radius: 5px;
      //   border: none;
      //   cursor: pointer;
      //   font-size: 12px;
      //   font-weight: 600;
      //   opacity: 1;
      //   font-family: 'Roboto Mono', monospace;
      //   z-index: 101;
      //   border: 1px solid black;
      // }

      

      .card-item-container {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;

        .image-project-box {
          padding: 10px;
          z-index: 100;
          border-radius: 15px;

          .video-player{
            width: 100%;
            height: auto;
            border-radius: 5px;
          }
        }

        .image-project {
          border-radius: 10px;
        }

        .card-item-right {
          padding: 10px 25px;
          overflow-y: scroll;
        }

        .text-client {
          font-weight: 600;
        }

        .text-services {
          color: #4D4D4D;
          margin-top: 5px;
        }
        .project-title {
          font-weight: 700;
          margin: 5px 0 15px 0;
        }
        .text-services-value {
          margin-top: 3px;      
        }
      }
    }
  }
}

.list-project-container::-webkit-scrollbar {
  display: none;
}
